import NarrowButton from '@slkit/Narrow/NarrowButton';
import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import { ApolloError } from 'apollo-client';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { focusError, formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import useLoading from 'lib/hooks/useLoading';
import * as React from 'react';
import EntryPassengersForm from './EntryPassengersForm';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'lib/hooks/useRouter';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import useQuery from 'lib/hooks/useQuery';
import { combineUrlWithQueryParams } from '../utils';
import useUEFABasketEntries from 'lib/hooks/useUEFABasketEntries';
import useBasketContent from 'lib/hooks/useBasketContent';
import { CurrencyEnum } from '../../../../graphql';
import './index.scss';
import usePassengersForm from './usePassengersForm';

const EventPassengersInformationStep = ({ slug }: { slug: string }) => {
    const queryParams = useQuery<{
        isTravelOnly: string;
        count: string;
    }>();
    const isTravelOnly = queryParams?.isTravelOnly === 'true';

    const history = useHistory();
    const {
        eventTurnitBasketId,
        basketEventSlug,
        setEventBasketId,
    } = React.useContext(ApplicationDataContext);

    const basket = useEventBasketContent();
    const turnitEventBasket = useBasketContent(eventTurnitBasketId);

    const {
        passengers,
        handlePassengerChange,
        loading,
        reload,
        error: fetchError,
        submit,
    } = usePassengersForm(eventTurnitBasketId);

    const { entries } = useUEFABasketEntries(eventTurnitBasketId);

    const { t } = useI18n('event_passengers_info');
    const [submitError, setSubmitError] = React.useState<ApolloError | null>(
        null
    );
    const [shouldFocusError, setFocusError] = React.useState(false);
    const { loading: submitLoading, beginLoading, endLoading } = useLoading(
        false
    );

    React.useEffect(() => {
        if (shouldFocusError) {
            focusError();
            setFocusError(false);
        }
    }, [shouldFocusError]);

    React.useEffect(() => {
        if (basketEventSlug && basketEventSlug !== slug) {
            setEventBasketId();
        }
    }, [basketEventSlug]);

    const handleFormSubmit = () => {
        setSubmitError(null);
        beginLoading();
        if (eventTurnitBasketId) {
            return submit(eventTurnitBasketId)
                .then(res => {
                    if (res) {
                        (reload ? reload() : Promise.resolve([])).then(() => {
                            window.scrollTo(0, 0);
                            history.push(
                                combineUrlWithQueryParams(
                                    `/event/${slug}/purchase/payment`,
                                    queryParams
                                )
                            );
                            endLoading();
                        });
                    } else {
                        endLoading();
                        setFocusError(true);
                    }
                })
                .catch(error => {
                    endLoading();
                    setSubmitError(error);
                    setFocusError(true);
                });
        } else {
            return Promise.resolve(false);
        }
    };

    const price = turnitEventBasket?.data?.basket?.content?.totalPrice ?? 0;

    if (!basket.loading && !basket?.data?.eventBasket) {
        if (isTravelOnly) {
            return (
                <Redirect
                    to={combineUrlWithQueryParams(
                        `/event/${slug}/purchase/passengers-count`,
                        queryParams
                    )}
                />
            );
        }
        return <Redirect to={`/event/${slug}/purchase/match`} />;
    }

    if (loading) return <LoadingIndicator />;
    if (fetchError) return <ErrorMessage error={fetchError} fixed />;

    return (
        <>
            <NarrowTitle className="EventPassengers__Title">
                <span>{t('title')}</span>
                <div className="EventPassengers__Title__Price">
                    <span className="EventPassengers__Title__Price__Total">
                        {formatPrice(price, CurrencyEnum.EUR)}
                    </span>
                </div>
            </NarrowTitle>
            <NarrowText className="EventPassengers__Text">
                {t('description')}
            </NarrowText>
            {entries &&
                passengers &&
                entries.map((entry, i) => (
                    <EntryPassengersForm
                        key={i}
                        entry={entry}
                        passengers={passengers}
                        onChange={handlePassengerChange}
                    />
                ))}
            {submitError && (
                <ErrorMessage
                    fixed
                    error={submitError}
                    onClose={() => setSubmitError(null)}
                />
            )}
            {submitLoading && <LoadingIndicator />}
            <NarrowButton onClick={handleFormSubmit}>
                {t('next-button-payment')}
            </NarrowButton>
        </>
    );
};

export default EventPassengersInformationStep;
