import * as React from 'react';
import './index.scss';

interface IProps {
    children?: React.ReactNode;
    title?: string;
}

const NoResultEvent = ({ children, title }: IProps) => (
    <div className="Narrow">
        <div className="Noresult">
            <h2 className="Noresult__Title">{title}</h2>
            <p className="Noresult__Text">{children}</p>
        </div>
    </div>
);

export default NoResultEvent;
