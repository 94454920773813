import EventFooter from 'components/EventPurchaseProcess/EventFooter';
import EventPurchaseProcessContainer from 'components/EventPurchaseProcess/EventPurchaseProcessContainer';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import EventLayout from 'modules/application/EventLayout';
import * as React from 'react';
import { useEventBySlugQuery } from '../../graphql';
import { UEFAAPIContext } from 'components/EventPurchaseProcess/constants';
import NotFoundPage from './NotFoundPage';

const EventPurchaseProcessPage = () => {
    const {
        match: {
            params: { slug },
        },
    } = useRouter<{ slug: string }>();

    const { error: eventBySlugError } = useEventBySlugQuery({
        context: UEFAAPIContext,
        variables: {
            slug,
        },
    });

    const { t } = useI18n('event_footer');

    if (eventBySlugError) {
        return <NotFoundPage />;
    }

    return (
        <EventLayout
            footerContent={
                <span
                    style={{
                        color: 'rgba(36, 36, 36, 0.32)',
                        fontSize: '10px',
                        fontWeight: 400,
                        textAlign: 'left',
                    }}
                >
                    {t('info')}
                </span>
            }
        >
            <div className="container">
                <div className="Page">
                    <EventPurchaseProcessContainer />
                </div>
            </div>
            <EventFooter />
        </EventLayout>
    );
};

export default EventPurchaseProcessPage;
