import * as React from 'react';
import { EventBySlugQuery } from '../../../../graphql';
import { IEventCountriesWithStops } from '.';
import EventRouteCountryCity from './EventRouteCountryCity';
import { ICombinedJourneys } from './EventRouteResults/EventRouteResultsJourney';

interface IEventRouteCountry {
    country: IEventCountriesWithStops;
    count: number;
    data: EventBySlugQuery['eventBySlug'];
    destinationBusStopId?: number;
    onSubmit: (combinedJourneys: ICombinedJourneys) => void;
    onCitySelect: (cityStopId: number) => void;
    selectedCity?: number;
}

const EventRouteCountry = ({
    country,
    onCitySelect,
    selectedCity,
    ...rest
}: IEventRouteCountry) => {
    if (country?.cities.length === 0) {
        return null;
    }

    return (
        <div className="EventRoute__Country">
            <div className="EventRoute__Country__Title">
                {country.countryName}
            </div>
            <div className="EventRoute__Country__Cities">
                {country?.cities.map((city, index) => (
                    <EventRouteCountryCity
                        key={index}
                        {...rest}
                        city={city}
                        selectedCity={selectedCity}
                        onCitySelect={onCitySelect}
                    />
                ))}
            </div>
        </div>
    );
};

export default EventRouteCountry;
