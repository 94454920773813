import { ApolloError } from 'apollo-client';
import { buildBasketEntries } from 'components/Basket/helpers';
import { useMemo } from 'react';
import { PassengerJourneysFragment } from '../../../graphql';
import useBasketContent from './useBasketContent';
import useUEFABasketContent from './useUEFABasketContent';

export interface IBasketEntry {
    inboundTripData?: ITripData;
    outboundTripData: ITripData;
    products: PassengerJourneysFragment[];
}

interface IBasketEntriesResult {
    loading: boolean;
    error?: ApolloError;
    entries: IBasketEntry[];
}

const NO_ENTRIES = [];

const useUEFABasketEntries = (basketId?: string): IBasketEntriesResult => {
    const { data, loading, error } = useUEFABasketContent(basketId);

    const entries = useMemo(
        () => (data ? buildBasketEntries(data.basket.content) : NO_ENTRIES),
        [data]
    );

    return {
        entries,
        error,
        loading,
    };
};

export default useUEFABasketEntries;
