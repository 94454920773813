import { useApolloClient } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import * as React from 'react';

import {
    addBasketJourney,
    addCampaignCode,
    changePassword,
    changeReservedSeat,
    createBasket,
    deleteBasket,
    deleteBasketProducts,
    devPayment,
    IAddBasketJourney,
    IAddCampaignCode,
    IChangePassword,
    IChangeReservedSeat,
    ICreateBasket,
    IDeleteBasket,
    IDeleteBasketProducts,
    IDevPayment,
    IPayWithVoucher,
    IPayWithZeroPayment,
    IRegisterUser,
    IUpdateBuyerInfo,
    IUpdateCustomerInfo,
    payWithVoucher,
    payWithZeroPayment,
    registerUser,
    updateBuyerInfo,
    updateCustomerInfo,
} from '../graphql/mutations';
import useBasketIdCookie from './hooks/useBasketIdCookie';
import { Language } from './hooks/useI18n';
import localStorage from './localStorage';
import useEventBasketIdCookie from './hooks/useEventBasketIdCookie';
import { EventBasket } from '../../graphql';

export interface IApplicationDataContext {
    addBasketJourney?: IAddBasketJourney;
    addCampaignCode?: IAddCampaignCode;
    apolloClient?: ApolloClient<any>;
    changePassword?: IChangePassword;
    changeReservedSeat?: IChangeReservedSeat;
    createBasket?: ICreateBasket;
    deleteBasket?: IDeleteBasket;
    deleteBasketProducts?: IDeleteBasketProducts;
    devPayment?: IDevPayment;
    language?: Language;
    payWithZeroPayment?: IPayWithZeroPayment;
    registerUser?: IRegisterUser;
    payWithVoucher?: IPayWithVoucher;
    updateBuyerInfo?: IUpdateBuyerInfo;
    updateCustomerInfo?: IUpdateCustomerInfo;

    basketId?: string;
    apiBaseUrl: string;
    apiUri?: string;
    setBasketId: (id?: string) => void;
    expireBasket: (reason?: string) => void;

    eventBasketId?: string;
    setEventBasketId: (
        id?: string,
        expiresAt?: EventBasket['expiresAt'],
        eventSlug?: string
    ) => void;
    expireEventBasket: (reason?: string) => void;

    eventTurnitBasketId?: string;
    setEventTurnitBasketId: (id?: string) => void;

    basketEventSlug?: string;
}

export const ApplicationDataContext = React.createContext<IApplicationDataContext>(
    {
        apiBaseUrl: '',
        setBasketId: () => {
            console.warn('no setBasketId implementation');
        },
        expireBasket: () => {
            console.warn('no expireBasket implementation');
        },
        setEventBasketId: () => {
            console.warn('no setEventBasketId implementation');
        },
        expireEventBasket: () => {
            console.warn('no expireEventBasket implementation');
        },
        setEventTurnitBasketId: () => {
            console.warn('no setEventTurnitBasketId implementation');
        },
    }
);

interface IProps {
    apiUri?: string;
    children: React.ReactNode;
    language?: Language;
}

export const DefaultApplicationDataProvider = ({
    apiUri,
    children,
    language,
}: IProps) => {
    const client = useApolloClient();
    const { basketIdCookie, setBasketIdCookie } = useBasketIdCookie();
    const {
        eventBasketIdCookie,
        basketEventSlugCookie,
        setEventBasketIdCookie,
    } = useEventBasketIdCookie();

    const [basketId, setBasketId] = React.useState<string | undefined>(
        basketIdCookie
    );
    const [eventBasketId, setEventBasketId] = React.useState<
        string | undefined
    >(eventBasketIdCookie);

    const [eventTurnitBasketId, setEventTurnitBasketId] = React.useState<
        string | undefined
    >();

    const apiBaseUrl = apiUri ? apiUri.replace('/graphql', '') : '/';

    const updateBasketId = (value?: string) => {
        setBasketIdCookie(value);
        setBasketId(value);
    };

    const updateEventBasketId = (
        value?: string,
        expiresAt?: EventBasket['expiresAt'],
        eventSlug?: string
    ) => {
        setEventBasketIdCookie(value, expiresAt, eventSlug);
        setEventBasketId(value);

        if (!value) {
            localStorage.setCityStopId(undefined);
        }
    };

    const handleExpireBasket = (reason?: string) => {
        if (reason != null) {
            localStorage.setNotice(reason);
        }
        updateBasketId();
    };

    const handleExpireEventBasket = (reason?: string) => {
        if (reason != null) {
            localStorage.setNotice(reason);
        }
        localStorage.setCityStopId(undefined);
        updateEventBasketId();
    };

    const applicationData: IApplicationDataContext = {
        addBasketJourney: addBasketJourney(client),
        addCampaignCode: addCampaignCode(client),
        apiBaseUrl,
        apiUri,
        apolloClient: client,
        basketId,
        eventBasketId,
        changePassword: changePassword(client),
        changeReservedSeat: changeReservedSeat(client),
        createBasket: createBasket(client),
        deleteBasket: deleteBasket(client),
        deleteBasketProducts: deleteBasketProducts(client),
        devPayment: devPayment(client),
        language,
        payWithVoucher: payWithVoucher(client),
        payWithZeroPayment: payWithZeroPayment(client),
        registerUser: registerUser(client),
        setBasketId: updateBasketId,
        setEventBasketId: updateEventBasketId,
        expireBasket: handleExpireBasket,
        expireEventBasket: handleExpireEventBasket,
        updateBuyerInfo: updateBuyerInfo(client),
        updateCustomerInfo: updateCustomerInfo(client),
        eventTurnitBasketId,
        setEventTurnitBasketId,
        basketEventSlug: basketEventSlugCookie,
    };

    return (
        <ApplicationDataContext.Provider value={applicationData}>
            {children}
        </ApplicationDataContext.Provider>
    );
};

export const useAppContext = () => React.useContext(ApplicationDataContext);
