import * as React from 'react';

import { IEventCount } from '..';
import { EventBySlugQuery } from '../../../../../graphql';
import './index.scss';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import useI18n from 'lib/hooks/useI18n';
import EventPoolsItem from './EventPoolsItem';

const DELAY = 1000;

interface IProps {
    data: EventBySlugQuery['eventBySlug'];
    onSubmit: (pools: IEventCount) => void;
    maxTickets?: number;
}

const EventPools = ({ data, onSubmit, maxTickets: iMaxTickets }: IProps) => {
    const { t } = useI18n('event_summary.pools');
    const timeoutRef = React.useRef<number>();
    const basket = useEventBasketContent();

    const cancel = () => {
        if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    };

    const [poolsCount, setPoolsCount] = React.useState<IEventCount>(
        basket.data?.eventBasket?.pools.reduce(
            (acc, pool) => ({
                ...acc,
                [pool.id]: pool.ticketsCount,
            }),
            {}
        ) ?? {}
    );

    const allTicketsLocal = React.useMemo(
        () => Object.values(poolsCount).reduce((acc, val) => acc + val, 0),
        [poolsCount]
    );
    const handleCountChange = (poolId: string, count: number) => {
        const newPools = {
            ...poolsCount,
            [poolId]: count,
        };
        setPoolsCount(newPools);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = window.setTimeout(() => {
            onSubmit(newPools);
        }, DELAY);
    };

    React.useEffect(() => () => cancel(), []);
    return (
        <div className="EventPool">
            {data?.pools?.map(pool => (
                <EventPoolsItem
                    key={pool.id}
                    pool={pool}
                    poolsCount={poolsCount}
                    onChange={handleCountChange}
                    maxTickets={iMaxTickets}
                    basket={basket}
                    allTicketsLocal={allTicketsLocal}
                />
            ))}
        </div>
    );
};

export default EventPools;
