import * as React from 'react';

import { formatPrice } from 'lib/helpers';
import moment, { duration } from 'moment';
import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import { EventJourneysSearchQuery } from '../../../../../graphql';
import useEventTurnitBasketContent from 'lib/hooks/useEventTurnitBasketContent';
import useI18n from 'lib/hooks/useI18n';
import FormattedText from 'components/FormattedText';

const getDurationFromString = (durationString: string) => {
    const durationMoment = moment.duration(durationString);
    return `${Math.floor(
        durationMoment.asHours()
    )}h ${durationMoment.minutes()}min`;
};

export interface ICombinedJourneys {
    outboundJourney: EventJourneysSearchQuery['journeysSearch']['journeys'][0];
    inboundJourney?: EventJourneysSearchQuery['journeysSearch']['journeys'][0];
}

interface IProps {
    journeyPair: ICombinedJourneys;
    count: number;
    onJourneysClick: (journeys: ICombinedJourneys) => void;
}

const EventRouteResultsJourney = ({
    journeyPair,
    count,
    onJourneysClick,
}: IProps) => {
    const { t, i } = useI18n('event_route');
    const turnitEventBasket = useEventTurnitBasketContent();
    const journeyBrandName = journeyPair.outboundJourney.legs[0].brandName;

    const outboundJourneyName = `${
        journeyPair.outboundJourney.originStopId ?? ''
    }-${journeyPair.outboundJourney.destinationStopId ?? ''}`;
    const inboundJourneyName = `${
        journeyPair?.inboundJourney?.originStopId ?? ''
    }-${journeyPair?.inboundJourney?.destinationStopId ?? ''}`;

    const journeyPairSpecialName = `${outboundJourneyName}_${inboundJourneyName}_${journeyBrandName}`;

    const isSelected =
        turnitEventBasket.journeyPairSpecialName === journeyPairSpecialName;

    const { outboundJourney, inboundJourney } = journeyPair;

    const outboundTime = moment.tz(
        outboundJourney.departureDateTime,
        outboundJourney.departureDateTimeTimeZone
    );
    const inboundTime = inboundJourney
        ? moment.tz(
              inboundJourney.departureDateTime,
              inboundJourney.departureDateTimeTimeZone
          )
        : null;

    const outboundPrice =
        outboundJourney?.campaignPrice ?? outboundJourney?.regularPrice ?? 0;

    const inboundPrice =
        inboundJourney?.campaignPrice ?? inboundJourney?.regularPrice ?? 0;

    const currency = outboundJourney.currency ?? inboundJourney?.currency;

    const totalPrice = outboundPrice + inboundPrice;

    const fullPrice =
        (outboundJourney?.regularPrice ?? 0) +
        (inboundJourney?.regularPrice ?? 0);

    const showDiscount =
        outboundJourney?.campaignPrice || inboundJourney?.campaignPrice;

    const isForSale = outboundJourney.isForSale && inboundJourney?.isForSale;

    return (
        <div
            className={cx('EventRouteResult__Item', {
                'EventRouteResult__Item--disabled': !isForSale,
            })}
            onClick={() =>
                !isSelected && isForSale && onJourneysClick(journeyPair)
            }
        >
            <div
                className={cx('EventRouteResult__Item__Radio', {
                    'EventRouteResult__Item__Radio--active': isSelected,
                })}
            >
                {isSelected && (
                    <Icon
                        className="EventRouteResult__Item__Radio__Icon"
                        name={ICON_NAME.CHECK}
                    />
                )}
            </div>
            <div className="EventRouteResult__Item__Content">
                <div className="EventRouteResult__Item__Content__Header">
                    <span className="EventRouteResult__Item__Content__Header__Time">
                        {i(t('departure_datetime'), {
                            date: outboundTime.format('D.M.YYYY'),
                            time: outboundTime.format('HH:mm'),
                        })}
                    </span>

                    {isForSale && (
                        <div className="EventRouteResult__Item__Content__Header__Price">
                            {fullPrice && showDiscount && (
                                <>
                                    <span className="EventRouteResult__Item__Content__Header__Price__Regular">
                                        {formatPrice(
                                            fullPrice,
                                            outboundJourney.currency
                                        )}
                                    </span>{' '}
                                </>
                            )}
                            <span className="EventRouteResult__Item__Content__Header__Price__Final">
                                {formatPrice(
                                    totalPrice,
                                    outboundJourney.currency
                                )}
                            </span>
                        </div>
                    )}
                    {!isForSale && (
                        <span>
                            <FormattedText>{t('sold_out')}</FormattedText>
                        </span>
                    )}
                </div>
                <div className="EventRouteResult__Item__Content__Description">
                    <FormattedText>
                        {i(t('journey_info'), {
                            ticketsCount: count,
                            originCityName: outboundJourney.originCityName,
                            originStopName: outboundJourney.originStopName,
                            destinationCityName:
                                outboundJourney.destinationCityName,
                            destinationStopName:
                                outboundJourney.destinationStopName,
                            inboundTime: inboundTime?.format('HH:mm'),
                            journeyDuration: outboundJourney.duration
                                ? getDurationFromString(
                                      outboundJourney.duration
                                  )
                                : undefined,
                        })}
                    </FormattedText>
                </div>
            </div>
        </div>
    );
};

export default EventRouteResultsJourney;
