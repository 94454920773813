import Accordion from '@slkit/Accordion';
import AccordionTile from '@slkit/Accordion/AccordionTile';
import Button from '@slkit/Button';
import Check from '@slkit/Check';
import Icon, { ICON_NAME } from '@slkit/Icon';
import Message from '@slkit/Message';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import { PaymentType } from '@slkit/PaymentMethod';
import PaymentMethodSelector from '@slkit/PaymentMethodSelector';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import { ICardData } from 'lib/@adyen/AdyenCardPaymentMethod';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { PaymentInfoQuery } from '../../../../graphql';
import CreditCardPaymentMethod, {
    CreditCardPaymentMethodHeader,
    ICardPaymentMethod,
} from 'components/PurchaseProcess/Payment/Methods/CreditCardPaymentMethod';
import GPayPaymentMethod, {
    GPayPaymentMethodButton,
    GPayPaymentMethodHeader,
} from 'components/PurchaseProcess/Payment/Methods/GPayPaymentMethod';
import ApplePayPaymentMethod, {
    ApplePayPaymentMethodButton,
    ApplePayPaymentMethodHeader,
} from 'components/PurchaseProcess/Payment/Methods/ApplePayPaymentMethod';
import { IExistingPaymentMethod } from 'components/PurchaseProcess/Payment/useExistingPaymentMethods';
import './index.scss';

interface IProps {
    error?: Error;
    onApplePaySubmit?: () => void;
    onCardSubmit: (cardData: ICardData) => void;
    onDevSubmit?: () => void;
    onGooglePaySubmit?: () => void;
    onZeroPaymentSubmit: () => void;
    onErrorClose: () => void;
    onExistingPaymentMethodSubmit: (
        method: IExistingPaymentMethod,
        cvc?: string
    ) => void;
    onTypeChange: (type: PaymentType) => void;
    onPaymentDelete: (paymentId: string) => Promise<any>;
    paymentInfo: PaymentInfoQuery['basket']['paymentInfo'];
    type: PaymentType;
}

const Payment = ({
    error: iError,
    onApplePaySubmit,
    onCardSubmit,
    onDevSubmit,
    onErrorClose,
    onExistingPaymentMethodSubmit,
    onGooglePaySubmit,
    onZeroPaymentSubmit,
    onTypeChange,
    paymentInfo,
    type,
}: IProps) => {
    const { i, t } = useI18n('payment');
    const [cardMethod, setCardMethod] = React.useState<ICardPaymentMethod>({});
    const [localError, setError] = React.useState<Error>();
    const [aggree, setAgree] = React.useState(false);

    const ACTIVE_METHODS = React.useMemo(() => {
        const methods: PaymentType[] = [PaymentType.CREDIT_CARD];

        if (onApplePaySubmit) methods.push(PaymentType.APAY);
        if (onGooglePaySubmit) methods.push(PaymentType.GPAY);

        return methods;
    }, [onGooglePaySubmit]);

    let canSubmit = false;

    switch (type) {
        case PaymentType.ZERO_PAYMENT:
            canSubmit = true;
            break;
        case PaymentType.CREDIT_CARD:
            if (cardMethod.existingPaymentMethod) {
                // If existing payment method is selected, check if that method requires cvc
                if (cardMethod.existingPaymentMethod.requiresCVC) {
                    // If cvc is required, check if cvc is present
                    canSubmit = !!cardMethod.existingPaymentMethodCVC;
                } else {
                    // If cvc is not required, method can be submitted
                    canSubmit = true;
                }
            } else {
                // If not trying to submit with existing payment method, card data is required
                canSubmit = !!cardMethod.cardData;
            }
            break;
        default:
            break;
    }

    const handleSubmit = React.useCallback(() => {
        setError(undefined);
        if (!aggree) {
            setError(new Error(t('toc_required')));
            return;
        }

        if (type === PaymentType.ZERO_PAYMENT) {
            onZeroPaymentSubmit();
            return;
        }

        if (type === PaymentType.CREDIT_CARD) {
            if (cardMethod.cardData) {
                // Process with card data
                if (onCardSubmit) onCardSubmit(cardMethod.cardData);
                return;
            }

            if (cardMethod.existingPaymentMethod) {
                // Process with selected method
                if (onExistingPaymentMethodSubmit) {
                    onExistingPaymentMethodSubmit(
                        cardMethod.existingPaymentMethod,
                        cardMethod.existingPaymentMethodCVC
                    );
                }
            }
        }

        if (type === PaymentType.GPAY) {
            if (onGooglePaySubmit) {
                onGooglePaySubmit();
            }
        }

        if (type === PaymentType.APAY) {
            if (onApplePaySubmit) {
                onApplePaySubmit();
            }
        }
    }, [aggree, cardMethod, onCardSubmit, onExistingPaymentMethodSubmit, type]);

    const error = iError || localError;
    const paymentAmount = paymentInfo.payableAmount || 0;

    const accHeader = (paymentType: PaymentType, active: boolean) => {
        switch (paymentType) {
            case PaymentType.CREDIT_CARD:
                return <CreditCardPaymentMethodHeader active={active} />;

            case PaymentType.GPAY:
                return <GPayPaymentMethodHeader active={active} />;

            case PaymentType.APAY:
                return <ApplePayPaymentMethodHeader active={active} />;

            default:
                return null;
        }
    };

    const accTileContent = (paymentType: PaymentType, active: boolean) => {
        switch (paymentType) {
            case PaymentType.CREDIT_CARD:
                return (
                    <CreditCardPaymentMethod
                        onMethodChange={setCardMethod}
                        anonymous={true}
                    />
                );

            case PaymentType.GPAY:
                return <GPayPaymentMethod />;

            case PaymentType.APAY:
                return <ApplePayPaymentMethod />;

            default:
                return null;
        }
    };

    return (
        <>
            {onDevSubmit && (
                <>
                    <br />
                    <Button onClick={onDevSubmit} full>
                        DEVELOPMENT PAYMENT
                    </Button>
                    <br />
                </>
            )}
            {error && (
                <ErrorMessage
                    error={error}
                    fixed
                    noPortal
                    onClose={
                        localError ? () => setError(undefined) : onErrorClose
                    }
                />
            )}
            <NarrowTitle className="EventPayment__Title">
                <span>{t('title')}</span>
                <span className="EventPayment__Title__Price">
                    {formatPrice(
                        paymentInfo.payableAmount!,
                        paymentInfo.currency!
                    )}
                </span>
            </NarrowTitle>
            {paymentAmount === 0 && (
                <div
                    className="PaymentMethod__Description"
                    style={{ marginTop: '10px', marginBottom: '1rem' }}
                >
                    {t('zero-payment-message')}
                </div>
            )}
            {paymentAmount > 0 && (
                <Message className="mb-1" success>
                    <Icon name={ICON_NAME.SECURE} />
                    {t('encryption-message')}
                </Message>
            )}
            <PaymentMethodSelector>
                {paymentAmount > 0 && (
                    <Accordion>
                        {ACTIVE_METHODS.map(method => (
                            <AccordionTile
                                key={method}
                                active={type === method}
                                onClick={() => onTypeChange(method)}
                                header={accHeader(method, type === method)}
                            >
                                {accTileContent(method, type === method)}
                            </AccordionTile>
                        ))}
                    </Accordion>
                )}

                <div style={{ padding: '10px 24px' }}>
                    <div style={{ marginTop: 10, marginBottom: '1rem' }}>
                        <Check
                            id="toc_checkbox"
                            isChecked={aggree}
                            label={
                                <FormattedText>
                                    {t('tos-agreement')}
                                </FormattedText>
                            }
                            onChange={setAgree}
                        />
                    </div>
                </div>

                {paymentInfo &&
                    type !== PaymentType.GPAY &&
                    type !== PaymentType.APAY && (
                        <Button
                            className="Narrow__Button"
                            style={{ margin: 0 }}
                            disabled={!canSubmit}
                            paypal={type === PaymentType.PAYPAL}
                            onClick={handleSubmit}
                        >
                            {i(t(`action.${type}`), {
                                price: formatPrice(
                                    paymentInfo.payableAmount!,
                                    paymentInfo.currency!
                                ),
                            })}
                        </Button>
                    )}
                {paymentInfo && type === PaymentType.APAY && (
                    <ApplePayPaymentMethodButton onClick={handleSubmit} />
                )}
                {paymentInfo && type === PaymentType.GPAY && (
                    <GPayPaymentMethodButton onClick={handleSubmit} />
                )}
            </PaymentMethodSelector>
        </>
    );
};

export default Payment;
