import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import {
    BasketSeatArrangementEnum,
    EventBySlugQuery,
} from '../../../../graphql';
import { formatDate } from 'lib/helpers';
import { IEventCount } from '.';
import Maybe from 'graphql/tsutils/Maybe';
import EventPools from './EventPools';

interface IProps {
    data: EventBySlugQuery['eventBySlug'];
    onContinue: () => void;
    isTravelOnly?: boolean;
    seatArrangement?: Maybe<BasketSeatArrangementEnum>;
    onSubmit: (pools: IEventCount) => void;
    maxTickets?: number;
    ticketsCount?: number;
}

const EventSummary = ({
    onContinue,
    data,
    seatArrangement,
    onSubmit,
    maxTickets,
    ticketsCount,
}: IProps) => {
    const { t } = useI18n('event_summary');

    const date = formatDate(data?.date, 'DD.MM.');
    return (
        <>
            <NarrowTitle className="Event__Title">
                {date} - {data?.name}
            </NarrowTitle>
            <NarrowText className="Event__Text">{t('text')}</NarrowText>
            <div className="Event">
                {data?.venueImageUrl && (
                    <img className="Event__Image" src={data?.venueImageUrl} />
                )}

                <div className="Event__SeatArrangement">
                    {seatArrangement === BasketSeatArrangementEnum.DISPERSED
                        ? t('seat_arrangement.dispersed')
                        : t('seat_arrangement.continuous')}
                </div>

                <EventPools
                    onSubmit={onSubmit}
                    data={data}
                    maxTickets={maxTickets}
                />
            </div>
            <div className="Narrow__Buttons">
                <button
                    disabled={!ticketsCount}
                    className="Button--inline Button"
                    onClick={onContinue}
                >
                    {t('button-title')}
                </button>
            </div>
        </>
    );
};

export default EventSummary;
