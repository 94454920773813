import * as cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    accessory?: React.ReactNode;
}

const NarrowTitle = ({
    accessory,
    className,
    children,
    ...props
}: React.HTMLAttributes<HTMLHeadingElement> & IProps) => {
    const hasAccessory = !!accessory;
    return (
        <h2
            className={cx('Narrow__Title', className, {
                'Narrow__Title--withAccessory': hasAccessory,
            })}
            {...props}
        >
            {hasAccessory ? <div>{children}</div> : children}
            {accessory && (
                <div className="Narrow__TitleAccessory">{accessory}</div>
            )}
        </h2>
    );
};

export default NarrowTitle;
