import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import * as React from 'react';
import EventSummary from './EventSummary';
import {
    UpdateEventBasketMutationVariables,
    useCreateEventBasketMutation,
    useDeleteEventTurnitBasketMutation,
    useEventBySlugQuery,
    useUpdateEventBasketMutation,
} from '../../../../graphql';
import './Event.scss';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import { UEFAAPIContext } from '../constants';
import NoResultEvent from '@slkit/NoResult/NoResultEvent';
import FormattedText from 'components/FormattedText';
import useI18n from 'lib/hooks/useI18n';
export interface IEventCount {
    [key: string]: number;
}

const EventSummaryContainer = ({ slug }: { slug: string }) => {
    const { t: tError } = useI18n('event_error');
    const basket = useEventBasketContent();

    const { setEventBasketId, basketEventSlug } = React.useContext(
        ApplicationDataContext
    );

    const { loading: customLoading, beginLoading, endLoading } = useLoading();

    const history = useHistory();
    const [error, setError] = React.useState<Error | undefined>();

    const { data, loading, refetch: refetchEventBySlug } = useEventBySlugQuery({
        context: UEFAAPIContext,
        variables: {
            slug,
        },
    });

    const allowedPools = data?.eventBySlug?.pools.map(pool => pool.id);

    const numberOfValidTickets = basket.data?.eventBasket?.pools.reduce(
        (acc, pool) => {
            if (allowedPools?.includes(pool.id)) {
                return acc + (pool?.ticketsCount ?? 0);
            }
            return acc;
        },
        0
    );

    const [deleteBasket] = useDeleteEventTurnitBasketMutation({
        context: UEFAAPIContext,
    });

    const [createBasketMutation] = useCreateEventBasketMutation({
        variables: { eventId: data?.eventBySlug?.id! },
    });
    const [updateBasketMutation] = useUpdateEventBasketMutation({
        context: UEFAAPIContext,
    });

    const handleError = (e: Error | undefined) => {
        endLoading();
        setError(e);
    };

    const handleSubmit = (pools: IEventCount) => {
        beginLoading();
        if (basket?.data?.eventBasket?.externalBasketId) {
            deleteBasket({ variables: { id: basket?.data?.eventBasket?.id } });
        }

        const basketIdPromise =
            basket.data?.eventBasket?.id && slug === basketEventSlug
                ? Promise.resolve({
                      basketId: basket.data?.eventBasket?.id!,
                      basketValidUntil: basket.data?.eventBasket?.expiresAt,
                  })
                : createBasketMutation().then(
                      result =>
                          result.data && {
                              basketId: result.data.createEventBasket.id!,
                              basketValidUntil:
                                  result.data.createEventBasket.expiresAt,
                          }
                  );

        basketIdPromise
            .then(createdBasket => {
                const filteredPools = Object.keys(pools).reduce(
                    (acc, poolId) => {
                        if (allowedPools?.includes(poolId)) {
                            acc[poolId] = pools[poolId];
                        }
                        return acc;
                    },
                    {} as IEventCount
                );

                const variables: UpdateEventBasketMutationVariables = {
                    id: createdBasket?.basketId!,
                    input: Object.keys(filteredPools).map(poolId => ({
                        poolId,
                        size: filteredPools[poolId],
                    })),
                };

                return updateBasketMutation({ variables })
                    .then(() => basket.reload && basket.reload())
                    .then(() =>
                        setEventBasketId(
                            createdBasket?.basketId!,
                            createdBasket?.basketValidUntil,
                            slug
                        )
                    )
                    .then(() => refetchEventBySlug())
                    .then(() => endLoading())
                    .catch(handleError);
            })
            .catch(handleError);
    };

    const handleContinue = () => {
        window.scrollTo(0, 0);
        history.push(`/event/${slug}/purchase/route`);
    };

    const availableTickets = data?.eventBySlug?.pools?.reduce(
        (acc, val) => acc + val.listedTickets,
        0
    );

    const isLoading = loading || customLoading || basket.loading;

    const maxTickets = data?.eventBySlug?.maxTicketsPerBasket ?? undefined;

    if (
        !isLoading &&
        data &&
        !data?.eventBySlug?.isPurchasable &&
        !data.eventBySlug?.purchasableFinished
    ) {
        return (
            <NoResultEvent title={tError('not_purchasable.title')}>
                <FormattedText>{tError('not_purchasable.text')}</FormattedText>
            </NoResultEvent>
        );
    }

    if (
        !isLoading &&
        data &&
        ((!availableTickets && !numberOfValidTickets) ||
            data?.eventBySlug?.purchasableFinished)
    ) {
        return (
            <NoResultEvent title={tError('no_tickets.title')}>
                <FormattedText>{tError('no_tickets.text')}</FormattedText>
            </NoResultEvent>
        );
    }

    return (
        <>
            {isLoading && <LoadingIndicator />}

            {data && (
                <EventSummary
                    key={basket.data?.eventBasket?.id}
                    data={data.eventBySlug}
                    isTravelOnly={basket.isTravelPassesOnly}
                    seatArrangement={basket.data?.eventBasket?.seatArrangement}
                    ticketsCount={numberOfValidTickets}
                    onSubmit={handleSubmit}
                    onContinue={handleContinue}
                    maxTickets={maxTickets}
                />
            )}

            <ErrorMessage
                error={error}
                fixed
                onClose={() => setError(undefined)}
            />
        </>
    );
};

export default EventSummaryContainer;
