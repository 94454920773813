import AppPromo from '@slkit/AppPromo';
import Grid, { GridColumn } from '@slkit/Grid';
import Message from '@slkit/Message';
import MessageText from '@slkit/Message/MessageText';
import MessageTitle from '@slkit/Message/MessageTitle';
import Narrow from '@slkit/Narrow';
import NarrowIconItem from '@slkit/Narrow/NarrowIconItem';
import NarrowText from '@slkit/Narrow/NarrowText';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import LoadingIndicator from 'components/LoadingIndicator';
import { useLogPurchase } from 'components/PurchaseProcess/useCheckoutStep';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBuyerInfo from 'lib/hooks/useBuyerInfo';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import * as React from 'react';
import { Redirect } from 'react-router';
import useBasketState from 'components/PurchaseSuccess/useBasketState';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import localStorage from 'lib/localStorage';
import useQuery from 'lib/hooks/useQuery';

const EventPurchaseSuccessContainer = () => {
    const {
        match: {
            params: { basketId },
        },
    } = useRouter<{ basketId: string }>();

    const { isTravelOnly: queryIsTravelOnly } = useQuery();
    const isTravelOnly = queryIsTravelOnly === 'true';

    const { t, i } = useI18n('event_purchase_success');

    const {
        setEventTurnitBasketId,
        setEventBasketId,
        eventTurnitBasketId: contextBasketId,
    } = React.useContext(ApplicationDataContext);
    const { data, error: basketContentError } = useBasketContent(basketId);
    const { data: eventBasket } = useEventBasketContent();
    const { data: buyerInfoData } = useBuyerInfo(basketId);
    const [printoutEventUrl, setPrintoutEventUrl] = React.useState<
        string | null
    >(null);
    const {
        state: basketState,
        loading: basketStateLoading,
        error: basketStateError,
    } = useBasketState(basketId);

    const loading = basketStateLoading;
    const error = basketContentError || basketStateError;

    // Clear context basket id
    // Maybe should be done after payment is handled correctly, instead here
    React.useEffect(() => {
        if (eventBasket?.eventBasket?.ticketsDownloadUrl) {
            setPrintoutEventUrl(eventBasket.eventBasket.ticketsDownloadUrl);
        }

        if (contextBasketId && basketState && basketState.state === 'paid') {
            setEventTurnitBasketId();
            setEventBasketId();
        }
    }, [contextBasketId, basketState]);

    const logPurchase = useLogPurchase(basketId);

    if (!basketId) return <Redirect to="/" />;
    if (loading) return <LoadingIndicator />;

    logPurchase();
    localStorage.setCityStopId(null);

    let buyerEmail: string = '';
    if (buyerInfoData) {
        buyerEmail = buyerInfoData.basket.buyerInfo.email || '';
    }

    if (error) {
        return (
            <>
                <Narrow>
                    <ErrorMessage error={error} fixed />
                </Narrow>
                <AppPromo />
            </>
        );
    }

    if (!basketState || basketState.state !== 'paid') {
        return (
            <>
                <Narrow>
                    <ErrorMessage
                        error={new Error(t('basket_not_paid_yet'))}
                        fixed
                    />
                </Narrow>
                <AppPromo />
            </>
        );
    }

    const message = () => {
        if (!data) return '';

        let key = 'message-description';

        if (isTravelOnly) {
            key = 'message-description-passengers-only';
        }

        return i(t(key), {
            email: buyerEmail,
        });
    };

    const printoutUrl = data && data.basket.printoutUrl;

    return (
        <>
            <Narrow>
                <Message center>
                    <MessageTitle>{t('message-success')}</MessageTitle>
                    <MessageText>
                        <FormattedText>{message()}</FormattedText>
                    </MessageText>
                </Message>
                <Grid
                    style={{
                        marginTop: '32px',
                        columnGap: '16px',
                        rowGap: '8px',
                    }}
                >
                    {printoutEventUrl && !isTravelOnly && (
                        <GridColumn gridWidth={1}>
                            <a
                                className="Button Button--secondary Button--download Button--red"
                                href={printoutEventUrl}
                                download
                                target="_blank"
                            >
                                {t('download-match-tickets-button')}
                            </a>
                        </GridColumn>
                    )}
                    {printoutUrl && (
                        <GridColumn gridWidth={1}>
                            <a
                                className="Button Button--secondary Button--download Button--red"
                                href={printoutUrl}
                                download
                                target="_blank"
                            >
                                {t('download-tickets-button')}
                            </a>
                        </GridColumn>
                    )}
                </Grid>
                <a
                    className="Button Button--primary"
                    style={{ marginTop: '8px', width: '100%' }}
                    href={'https://www.slovaklines.sk/futbal'}
                >
                    {t('button-title-next')}
                </a>
                <NarrowIconItem />
                <NarrowText>
                    <FormattedText>{t('recommendation')}</FormattedText>
                </NarrowText>
                <NarrowText>
                    <FormattedText>{t('info')}</FormattedText>
                </NarrowText>
            </Narrow>
            <AppPromo />
        </>
    );
};

export default EventPurchaseSuccessContainer;
