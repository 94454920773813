import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import * as React from 'react';

import { EventBasketQuery, useEventBasketQuery } from '../../../graphql';
import { UEFAAPIContext } from 'components/EventPurchaseProcess/constants';

interface IEventBasketContentResult {
    loading: boolean;
    data?: EventBasketQuery;
    error?: ApolloError;
    reload: () => Promise<any>;
    validUntil?: string;
    isTravelPassesOnly?: boolean;
}

const isTravelPassesOnly = (
    basket?: EventBasketQuery['eventBasket']
): boolean => {
    if (!basket) return false;
    return false;
};

const dummyReload = () => {
    console.warn('[useEventBasketContent] dummyReloadCalled');
    return Promise.resolve(false);
};

const useEventBasketContent = (
    basketId?: string
): IEventBasketContentResult => {
    const { eventBasketId, setEventTurnitBasketId } = React.useContext(
        ApplicationDataContext
    );

    const id = basketId ?? eventBasketId;

    const basketQuery = useEventBasketQuery({
        skip: !id,
        variables: { id: id! },
        notifyOnNetworkStatusChange: true,
        context: UEFAAPIContext,
    });

    React.useEffect(() => {
        setEventTurnitBasketId(
            basketQuery.data?.eventBasket?.externalBasketId ?? undefined
        );
    }, [basketQuery.data?.eventBasket?.externalBasketId]);

    if (!id) {
        return {
            loading: false,
            reload: dummyReload,
        } as IEventBasketContentResult;
    }

    const loading = basketQuery.loading;
    const error = basketQuery.error;

    const data = basketQuery.data;
    return {
        data,
        error,
        loading,
        validUntil: data?.eventBasket?.expiresAt,
        isTravelPassesOnly: data && isTravelPassesOnly(data.eventBasket),
        reload: basketQuery.refetch,
    };
};

export default useEventBasketContent;
