export const combineUrlWithQueryParams = (
    url: string,
    params: { [key: string]: any }
) => {
    const queryString = Object.keys(params)
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');
    return `${url}?${queryString}`;
};
