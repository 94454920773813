import Footer from 'components/Footer';
import Navbar from 'components/Navbar/Navbar';
import * as React from 'react';

interface IProps {
    footerContent?: React.ReactNode;
    children: React.ReactNode;
}

const Layout = ({ children, footerContent }: IProps) => (
    <>
        <Navbar />
        {children}
        <div id="fixed-notifications-container" />
        <Footer footerContent={footerContent} />
    </>
);

export default Layout;
