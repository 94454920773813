import Narrow from '@slkit/Narrow';
import * as React from 'react';
import { Route } from 'react-router';
import EventSummaryContainer from './EventSummary';
import useRouter from 'lib/hooks/useRouter';
import EventRouteContainer from './EventRoute';
import EventSteps from 'components/EventPurchaseProcess/EventSteps';
import PaymentContainer from './Payment';
import EventTravelOnlyContainer from './EventTravelOnly';
import Notice from 'components/Notice/Notice';
import PassengersInformation from './Passengers/PassengersInformation';

const EventPurchaseProcessContainer = () => {
    const {
        match: {
            params: { slug },
        },
    } = useRouter<{ slug: string }>();

    return (
        <Narrow>
            <EventSteps slug={slug} />
            <Route
                path="*/match"
                render={props => (
                    <EventSummaryContainer {...props} slug={slug} />
                )}
            />
            <Route
                path="*/passengers-count"
                render={props => (
                    <EventTravelOnlyContainer {...props} slug={slug} />
                )}
            />
            <Route
                path="*/route"
                render={props => <EventRouteContainer {...props} slug={slug} />}
            />
            <Route
                path="*/passengers"
                render={props => (
                    <PassengersInformation {...props} slug={slug} />
                )}
            />
            <Route
                path="*/payment"
                render={() => <PaymentContainer slug={slug} />}
            />
            <Notice />
        </Narrow>
    );
};

export default EventPurchaseProcessContainer;
