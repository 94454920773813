import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import findIndex from 'lodash/findIndex';
import * as React from 'react';

import { Link } from 'react-router-dom';
import StepDivider from './StepDivider';
import useQuery from 'lib/hooks/useQuery';
import { combineUrlWithQueryParams } from '../utils';

interface IParams {
    step: string;
}

interface IProps {
    slug: string;
    stepKey?: string;
}

const EventSteps = ({ slug, stepKey: iStepKey }: IProps) => {
    const { match } = useRouter<IParams>();
    const queryParams = useQuery();
    const stepKey = iStepKey || match.params.step;
    const { t } = useI18n('event_purchase_process');

    const steps = React.useMemo(
        () => [
            ...('passengers-count' === stepKey ||
            queryParams?.isTravelOnly === 'true'
                ? [
                      {
                          title: t('passengers-count-step'),
                          key: 'passengers-count',
                      },
                  ]
                : [{ title: t('match-step'), key: 'match' }]),
            { title: t('route-step'), key: 'route' },
            {
                title: t('passengers-step'),
                key: 'passengers',
            },
            { title: t('payment-step'), key: 'payment' },
        ],
        [t]
    );

    const activeStepIndex = findIndex(steps, item =>
        item ? item.key === stepKey : false
    );

    return (
        <div className="Steps">
            {steps.map((step, stepIndex) => (
                <React.Fragment key={stepIndex}>
                    {stepIndex !== 0 && (
                        <StepDivider isActive={activeStepIndex === stepIndex} />
                    )}
                    <Link
                        to={combineUrlWithQueryParams(
                            `/event/${slug}/purchase/${step.key}`,
                            queryParams
                        )}
                        style={{
                            cursor:
                                activeStepIndex > stepIndex
                                    ? 'pointer'
                                    : 'default',
                            textDecoration: 'none',
                        }}
                        className={cx('Steps__Item', {
                            'Steps__Item--active':
                                activeStepIndex === stepIndex,
                            'Steps__Item--done': activeStepIndex > stepIndex,
                        })}
                        onClick={e => {
                            if (activeStepIndex < stepIndex) e.preventDefault();
                        }}
                    >
                        <div className="Steps__Check">
                            <Icon name={ICON_NAME.CHECK} />
                        </div>
                        <span className="Steps__Number">{stepIndex + 1}</span>
                        {step.title}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default EventSteps;
