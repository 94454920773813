import * as React from 'react';
import { EventBySlugQuery } from '../../../../graphql';
import { IEventStop } from '.';
import cx from 'classnames';
import EventRouteResults from './EventRouteResults';
import { ICombinedJourneys } from './EventRouteResults/EventRouteResultsJourney';
import Icon, { ICON_NAME } from '@slkit/Icon';

interface IEventRouteCountryCity {
    city: IEventStop;
    count: number;
    data: EventBySlugQuery['eventBySlug'];
    destinationBusStopId?: number;
    onSubmit: (combinedJourneys: ICombinedJourneys) => void;
    selectedCity?: number;
    onCitySelect: (cityStopId: number) => void;
}

const EventRouteCountryCity = ({
    city,
    count,
    data,
    destinationBusStopId,
    onSubmit,
    selectedCity,
    onCitySelect,
}: IEventRouteCountryCity) => {
    return (
        <div
            className={cx('EventRoute__Country__City', {
                'EventRoute__Country__City--active':
                    selectedCity === city.stopId,
            })}
            onClick={() =>
                selectedCity !== city.stopId && onCitySelect(city.stopId)
            }
        >
            <div className="EventRoute__Country__City__Title">
                {city.cityName}
                <div className="EventRoute__Country__City__Title__Handler">
                    <Icon
                        className={cx(
                            'EventRoute__Country__City__Title__Handler__Icon',
                            {
                                'EventRoute__Country__City__Title__Handler__Icon--open':
                                    selectedCity === city.stopId,
                            }
                        )}
                        name={ICON_NAME.CHEVRON}
                    />
                </div>
            </div>
            <div
                className={cx('EventRoute__Country__City__Results', {
                    'EventRoute__Country__City__Results--active':
                        selectedCity === city.stopId,
                })}
            >
                {data?.date && destinationBusStopId && selectedCity && (
                    <EventRouteResults
                        count={count}
                        originBusStopId={selectedCity}
                        destinationBusStopId={destinationBusStopId}
                        date={data?.date}
                        onSubmit={onSubmit}
                    />
                )}
            </div>
        </div>
    );
};

export default EventRouteCountryCity;
