import EventFooter from 'components/EventPurchaseProcess/EventFooter';
import EventPurchaseSuccessContainer from 'components/EventPurchaseSuccess/EventPurchaseSuccessContainer';
import useI18n from 'lib/hooks/useI18n';
import EventLayout from 'modules/application/EventLayout';
import * as React from 'react';

const EventPurchaseSuccessPage = () => {
    const { t } = useI18n('event_footer');

    return (
        <EventLayout
            footerContent={
                <span
                    style={{
                        color: 'rgba(36, 36, 36, 0.32)',
                        fontSize: '10px',
                        fontWeight: 400,
                        textAlign: 'left',
                    }}
                >
                    {t('info')}
                </span>
            }
        >
            <div className="container">
                <div className="Page">
                    <EventPurchaseSuccessContainer />
                </div>
            </div>
            <EventFooter />
        </EventLayout>
    );
};

export default EventPurchaseSuccessPage;
