import * as store from 'store2';

enum KEY {
    LAST_SEARCH_QUERY = 'slo/LAST_SEARCH_QUERY',
    NOTICE = 'slo/NOTICE',
    CITY_STOP_ID = 'slo/CITY_STOP_ID',
}

type Value = string | null | undefined;

const _get = (key: KEY) => {
    return store.get(key);
};

const _set = (key: KEY, value: string) => {
    return store.set(key, value);
};

const _clear = (key: KEY) => {
    return store.remove(key);
};

const _setOrClear = (key: KEY, value: Value) => {
    if (value) {
        return _set(key, value);
    } else {
        return _clear(key);
    }
};

const localStorage = {
    getLastSearchQuery: (): string | undefined => {
        const query = _get(KEY.LAST_SEARCH_QUERY);
        return query === null ? undefined : (query as string);
    },
    setLastSearchQuery: (value: Value) => {
        return _setOrClear(KEY.LAST_SEARCH_QUERY, value);
    },
    getNotice: (): string | undefined => {
        const notice = _get(KEY.NOTICE);
        return notice === null ? undefined : (notice as string);
    },
    setNotice: (value: Value) => {
        return _setOrClear(KEY.NOTICE, value);
    },
    setCityStopId: (cityStopId: Value) => {
        return _setOrClear(KEY.CITY_STOP_ID, cityStopId);
    },
    getCityStopId: (): number | undefined => {
        const cityStopId = _get(KEY.CITY_STOP_ID);
        return cityStopId === null ? undefined : parseInt(cityStopId);
    },
};

export default localStorage;
