import { getCookieDomain } from 'lib/helpers';
import { useCookies } from 'react-cookie';
import { EventBasket } from '../../../graphql';

const EVENT_BASKET_ID_COOKIE = 'slo/eventBasketId';
const BASKET_EVENT_SLUG_COOKIE = 'slo/basketEventSlug';

const DEFAULT_COOKIE_MAX_AGE = 600;

interface IEventBasketIdCookie {
    eventBasketIdCookie?: string;
    basketEventSlugCookie?: string;
    setEventBasketIdCookie: (
        id?: string,
        expiresAt?: EventBasket['expiresAt'],
        eventSlug?: string
    ) => void;
}

const useEventBasketIdCookie = (): IEventBasketIdCookie => {
    const [cookies, setCookie, removeCookie] = useCookies([
        EVENT_BASKET_ID_COOKIE,
        BASKET_EVENT_SLUG_COOKIE,
    ]);

    const domain = getCookieDomain();

    return {
        eventBasketIdCookie: cookies[EVENT_BASKET_ID_COOKIE] as
            | string
            | undefined,
        basketEventSlugCookie: cookies[BASKET_EVENT_SLUG_COOKIE] as
            | string
            | undefined,
        setEventBasketIdCookie: (
            id?: string,
            expiresAt?: EventBasket['expiresAt'],
            eventSlug?: string
        ) => {
            const maxAge = expiresAt
                ? Math.floor(
                      (new Date(expiresAt).getTime() - new Date().getTime()) /
                          1000
                  )
                : DEFAULT_COOKIE_MAX_AGE;

            const options = {
                domain,
                maxAge,
                path: '/',
            };
            if (id === undefined) {
                removeCookie(EVENT_BASKET_ID_COOKIE, options);
                removeCookie(BASKET_EVENT_SLUG_COOKIE, options);
            } else {
                setCookie(EVENT_BASKET_ID_COOKIE, id, options);
                setCookie(BASKET_EVENT_SLUG_COOKIE, eventSlug, options);
            }
        },
    };
};

export default useEventBasketIdCookie;
