import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';
import FormattedText from 'components/FormattedText';
import { ReactComponent as SVG_UEFA_LOGO } from '@slkit/assets/svgs/uefa-logo-sl.svg';

const EventFooter = () => {
    const { t } = useI18n('event_footer');

    const columns = [
        {
            title: t('bus'),
            items: [t('bus.availability'), t('bus.more'), t('bus.faq')],
        },
        {
            title: t('documents'),
            items: [t('documents.privacy'), t('documents.schedule')],
        },
        {
            title: t('contact'),
            items: [t('contact.write_us')],
        },
    ];

    return (
        <div className="EventFooter container">
            <div className="EventFooter__Left">
                <div className="EventFooter__Left__Logo">
                    <SVG_UEFA_LOGO
                        style={{
                            height: '100px',
                        }}
                    />
                </div>
            </div>
            <div className="EventFooter__Right">
                {columns.map((column, index) => (
                    <div key={index} className="EventFooter__Right__Column">
                        <span className="EventFooter__Right__Column__Title">
                            <FormattedText>{column.title}</FormattedText>
                        </span>

                        <div className="EventFooter__Right__Column__List">
                            {column.items.map((item, index) => (
                                <FormattedText key={index}>
                                    {item}
                                </FormattedText>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventFooter;
