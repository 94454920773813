import InputField from '@slkit/InputField';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import PhoneNumber from '@slkit/PhoneNumber';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

interface IProps {
    onChange: (id: string, attributes: IPassengerDataAttributes) => void;
    passenger: IPassengerData;
}

const PassengerForm = ({ onChange, passenger }: IProps) => {
    const { t } = useI18n('event_passengers_info');

    const handleChange = React.useCallback(
        (attributes: IPassengerDataAttributes) => {
            onChange(passenger.id, attributes);
        },
        [onChange, passenger.id]
    );

    return (
        <>
            <InputField
                error={passenger.errors && passenger.errors.name}
                placeholder={t('name-placeholder')}
                required
                value={passenger.name || ''}
                onChange={event => handleChange({ name: event.target.value })}
            />
            {passenger.isBuyer && (
                <>
                    <InputField
                        placeholder={t('email-placeholder')}
                        value={passenger.email || ''}
                        error={passenger.errors && passenger.errors.email}
                        required
                        onChange={event =>
                            handleChange({ email: event.target.value })
                        }
                        type="email"
                    />
                    <PhoneNumber
                        areaCode={passenger.phoneAreaCode || ''}
                        error={passenger.errors && passenger.errors.phoneNumber}
                        placeholder={t('phone-number-placeholder')}
                        value={passenger.phoneNumber || ''}
                        required
                        onChange={event =>
                            handleChange({ phoneNumber: event.target.value })
                        }
                        onAreaCodeChange={data =>
                            handleChange({
                                country: data.countryName,
                                phoneAreaCode: data.areaCode,
                            })
                        }
                    />
                </>
            )}
        </>
    );
};

export default PassengerForm;
