import * as React from 'react';

import { formatPrice } from 'lib/helpers';
import { CurrencyEnum } from '__types__/globalTypes';
import Counter from '@slkit/Counter';
import { IEventCount } from '..';
import { EventBySlugQuery } from '../../../../../graphql';
import './index.scss';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import useI18n from 'lib/hooks/useI18n';

interface IProps {
    maxTickets?: number;
    poolsCount: IEventCount;
    pool: NonNullable<EventBySlugQuery['eventBySlug']>['pools'][0];
    basket: ReturnType<typeof useEventBasketContent>;
    onChange: (poolId: string, count: number) => void;
    allTicketsLocal: number;
}

const EventPoolsItem = ({
    maxTickets: iMaxTickets,
    poolsCount,
    pool,
    basket,
    onChange,
    allTicketsLocal,
}: IProps) => {
    const { t } = useI18n('event_summary.pools');

    let max = iMaxTickets ?? pool.listedTickets;

    const currentPoolCountLocal = poolsCount[pool.id] ?? 0;
    const currentPoolCountBasket =
        basket.data?.eventBasket?.pools.find(p => p.id === pool.id)
            ?.ticketsCount ?? 0;

    const otherPoolsCurrentCount = allTicketsLocal - currentPoolCountLocal;

    const currentPoolAvailableTickets =
        currentPoolCountBasket > 0
            ? pool.listedTickets + currentPoolCountBasket
            : pool.listedTickets;

    if (max) {
        max = Math.min(
            currentPoolAvailableTickets,
            max - otherPoolsCurrentCount
        );

        if (allTicketsLocal === iMaxTickets) {
            max = 0;
        }
    }

    return (
        <div className="EventPool__Item" key={pool.id}>
            <div className="EventPool__Item__Label">
                {t('sector')} {pool.sectorIdentifier}
            </div>
            <div className="EventPool__Item__Info">
                <span className="EventPool__Item__Info__Price">
                    {t('price_for_one')}{' '}
                    <strong>
                        {pool.priceCents
                            ? formatPrice(
                                  pool.priceCents / 100,
                                  CurrencyEnum.EUR
                              )
                            : '-'}
                    </strong>
                </span>
                <Counter
                    count={currentPoolCountLocal}
                    onChange={count => onChange(pool.id, count)}
                    max={max}
                />
            </div>
        </div>
    );
};

export default EventPoolsItem;
