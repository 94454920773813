import * as React from 'react';
import EventPassengersInformationStep from './PassengersInformationStep';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';

const PassengersInformation = (props: { slug: string }) => {
    const basket = useEventBasketContent();

    return (
        <EventPassengersInformationStep
            key={JSON.stringify(basket)}
            {...props}
        />
    );
};

export default PassengersInformation;
