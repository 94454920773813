import * as React from 'react';

import LoadingIndicator from 'components/LoadingIndicator';
import EventRouteResultsJourney, {
    ICombinedJourneys,
} from './EventRouteResultsJourney';
import { UEFAAPIContext } from 'components/EventPurchaseProcess/constants';
import {
    CurrencyEnum,
    useEventJourneysSearchQuery,
} from '../../../../../graphql';
import './index.scss';
import useI18n from 'lib/hooks/useI18n';

interface IProps {
    originBusStopId: number;
    destinationBusStopId: number;
    date: string;
    count: number;
    onSubmit: (journeyPair: ICombinedJourneys) => void;
}

const INITIAL_FARE_CLASSES_ID = 'BONUS_SCHEME_GROUP.ADULT';

const EventRouteResults = ({
    originBusStopId,
    destinationBusStopId,
    date,
    count,
    onSubmit,
}: IProps) => {
    const { t } = useI18n('event_route');
    const {
        data: outboundJourneys,
        loading: outboundLoading,
    } = useEventJourneysSearchQuery({
        variables: {
            journeys_search_input: {
                checkPaxSoldTogetherRules: false,
                isPartOfRoundTrip: true,
                originBusStopId,
                destinationBusStopId,
                currency: CurrencyEnum.EUR,
                departureDate: date,
                fareClasses: [
                    {
                        count,
                        id: INITIAL_FARE_CLASSES_ID,
                    },
                ],
            },
            skip_reinforcement_filter: true,
        },
        context: UEFAAPIContext,
    });

    const {
        data: inboundJourneys,
        loading: inboundLoading,
    } = useEventJourneysSearchQuery({
        variables: {
            journeys_search_input: {
                checkPaxSoldTogetherRules: false,
                isPartOfRoundTrip: true,
                originBusStopId: destinationBusStopId,
                destinationBusStopId: originBusStopId,
                currency: CurrencyEnum.EUR,
                departureDate: date,
                fareClasses: [
                    {
                        count,
                        id: INITIAL_FARE_CLASSES_ID,
                    },
                ],
            },
            skip_reinforcement_filter: true,
        },
        context: UEFAAPIContext,
    });

    const {
        data: fallbackInboundJourneys,
        loading: fallbackInboundLoading,
    } = useEventJourneysSearchQuery({
        variables: {
            journeys_search_input: {
                checkPaxSoldTogetherRules: false,
                isPartOfRoundTrip: true,
                originBusStopId: destinationBusStopId,
                destinationBusStopId: originBusStopId,
                currency: CurrencyEnum.EUR,
                departureDate: new Date(
                    new Date(date).setDate(new Date(date).getDate() + 1)
                )
                    .toISOString()
                    .split('T')[0],
                fareClasses: [
                    {
                        count,
                        id: INITIAL_FARE_CLASSES_ID,
                    },
                ],
            },
            skip_reinforcement_filter: true,
        },
        context: UEFAAPIContext,
        skip:
            !!inboundJourneys?.journeysSearch.journeys.length &&
            !inboundLoading,
    });

    const finalInboundJourneys = !!inboundJourneys?.journeysSearch?.journeys
        ?.length
        ? inboundJourneys
        : fallbackInboundJourneys;

    const finalInboundLoading = inboundLoading ?? fallbackInboundLoading;

    const combinedJourneys: ICombinedJourneys[] = React.useMemo(() => {
        if (!outboundJourneys || !finalInboundJourneys) return [];

        return outboundJourneys.journeysSearch.journeys.map(journey => {
            const inboundJourney = finalInboundJourneys.journeysSearch.journeys.find(
                inboundJourney =>
                    inboundJourney.legs.some(
                        leg => leg.brandName === journey.legs[0].brandName
                    )
            );

            return {
                outboundJourney: journey,
                inboundJourney,
            };
        });
    }, [outboundJourneys, finalInboundJourneys]);

    const hasCampaignPrice = combinedJourneys.some(
        journey =>
            journey.outboundJourney.campaignPrice ||
            journey.inboundJourney?.campaignPrice
    );

    const isLoading = outboundLoading || finalInboundLoading;

    return (
        <>
            {isLoading && <LoadingIndicator />}

            {!isLoading && (
                <div className="EventRouteResult">
                    {hasCampaignPrice && (
                        <div className="EventRouteResult__CampaignPrice">
                            {t('journey_has_campaign_price')}
                        </div>
                    )}
                    {combinedJourneys.map((journeyPair, index) => (
                        <EventRouteResultsJourney
                            key={index}
                            journeyPair={journeyPair}
                            count={count}
                            onJourneysClick={onSubmit}
                        />
                    ))}
                    {combinedJourneys.length === 0 && (
                        <div className="EventRouteResult__NoResults">
                            {t('no_results')}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default EventRouteResults;
