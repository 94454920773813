import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import * as React from 'react';

import { PaymentInfoQuery, usePaymentInfoQuery } from '../../../../graphql';

interface IPaymentInfo {
    loading: boolean;
    data?: PaymentInfoQuery['basket'];
    error?: ApolloError;
    reload: () => Promise<any>;
}

const usePaymentInfo = (iBasketId?: string): IPaymentInfo => {
    const { basketId } = React.useContext(ApplicationDataContext);

    const id = iBasketId || basketId;

    const { data, loading, error, refetch } = usePaymentInfoQuery({
        variables: {
            id: id!,
        },
        skip: !id,
        fetchPolicy: 'network-only',
    });

    return { data: data && data.basket, error, loading, reload: refetch };
};

export default usePaymentInfo;
