import * as React from 'react';
import {
    PayWithAppleMutation,
    PayWithAppleMutationVariables,
    PayWithResponseFragment,
    useAppleStartSessionMutation,
    usePayWithAppleMutation,
} from '../../../graphql';
import './index.scss';

const log = (...args) => console.log('[useApplePay]', ...args);

type TResult = PayWithResponseFragment | undefined;

interface IGooglePayAvailableResult {
    isAvailable: false;
}
interface IGooglePayUnavailableResult {
    isAvailable: true;
    handleSubmit: (
        amount: number,
        basketId: string,
        browserInfo?: PayWithAppleMutationVariables['browserInfo']
    ) => Promise<TResult>;
}
type TGooglePayResult = IGooglePayAvailableResult | IGooglePayUnavailableResult;

export const useApplePay = (mutationBaseOptions?: any): TGooglePayResult => {
    const [startSession] = useAppleStartSessionMutation(mutationBaseOptions);
    const [payWithApple] = usePayWithAppleMutation(mutationBaseOptions);

    const useApplePayResult = React.useMemo((): TGooglePayResult => {
        log('Initializing');

        try {
            if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) {
                log('ApplePay not available');
                return { isAvailable: false };
            }
        } catch (error) {
            log(error);
            return { isAvailable: false };
        }

        log('ApplePay available');

        const handleSubmit = (
            amount: number,
            basketId: string,
            browserInfo?: PayWithAppleMutationVariables['browserInfo']
        ) => {
            log('Begin payment for', amount);
            const total: ApplePayJS.ApplePayLineItem = {
                amount: amount.toFixed(2),
                label: 'Slovak Lines',
                // type: 'final',
            };

            const request: ApplePayJS.ApplePayPaymentRequest = {
                countryCode: 'SK',
                currencyCode: 'EUR',
                merchantCapabilities: ['supports3DS'],
                // other available 'amex', 'discover' do not support EUR as currency and only support USD
                // see https://docs.adyen.com/payment-methods/apple-pay/handle-apple-pay-errors#5_001-apple-pay-token-amount-mismatch
                supportedNetworks: ['visa', 'masterCard'],
                total,
            };
            const session = new ApplePaySession(3, request);

            return new Promise<TResult>((resolve, reject) => {
                session.oncancel = e => log('oncancel', e);
                session.onpaymentauthorized = e => {
                    const token = e.payment.token;
                    log('onpaymentauthorized', e);
                    log('token', token);
                    const tokenData = btoa(JSON.stringify(token.paymentData));

                    payWithApple({
                        variables: {
                            adyenPaymentInput: {
                                token: tokenData,
                            },
                            basketId,
                            browserInfo,
                        },
                    })
                        .then(res => {
                            session.completePayment({
                                status: ApplePaySession.STATUS_SUCCESS,
                            });
                            resolve(res.data && res.data.adyen.payWithApple);
                        })
                        .catch(error => {
                            session.completePayment({
                                status: ApplePaySession.STATUS_FAILURE,
                            });
                            reject(error);
                        });
                };

                session.onvalidatemerchant = e => {
                    log('onvalidatemerchant', e.validationURL);

                    startSession({
                        variables: { validationUrl: e.validationURL },
                    })
                        .then(res => {
                            const ses = res.data!.apple.startSession;
                            if (!ses) {
                                console.error(
                                    'Could not fetch ApplePay session'
                                );
                                session.abort();
                                reject(
                                    new Error(
                                        'Could not fetch ApplePay session'
                                    )
                                );
                            }
                            log('completeMerchantValidation', ses);
                            session.completeMerchantValidation(ses);
                        })
                        .catch(err => {
                            session.abort();
                            reject(err);
                        });
                };

                session.begin();
            });
        };

        return { isAvailable: true, handleSubmit };
    }, []);

    return useApplePayResult;
};
