import Header from '@slkit/Header';
import BasketContainer from 'components/Basket/BasketContainer';
import BasketCheckerContainer from 'components/BasketChecker/BasketCheckerContainer';
import BasketEventCheckerContainer from 'components/BasketChecker/BasketEventCheckerContainer';
import LanguagePicker from 'components/LanguagePicker';
import LoginContainer from 'components/Login';
import useMeta from 'lib/hooks/useMeta';
import * as React from 'react';

const Navbar = () => {
    const version = useMeta('x-show-version');
    return (
        <Header version={version}>
            <LanguagePicker compact direction="down" />
            <LoginContainer />
            <BasketContainer />
            <BasketCheckerContainer />
            <BasketEventCheckerContainer />
        </Header>
    );
};

export default Navbar;
