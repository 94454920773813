import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { CurrencyEnum, EventBySlugQuery } from '../../../../graphql';
import { IEventCountriesWithStops } from '.';
import './index.scss';
import Icon, { ICON_NAME } from '@slkit/Icon';
import { formatPrice } from 'lib/helpers';
import EventRouteCountry from './EventRouteCountry';
import { ICombinedJourneys } from './EventRouteResults/EventRouteResultsJourney';
import FormattedText from 'components/FormattedText';
import InputField from '@slkit/InputField';
import localStorage from 'lib/localStorage';

interface IProps {
    data: EventBySlugQuery['eventBySlug'];
    onContinue: () => void;
    onBack: () => void;
    onSearch?: (val: string) => void;
    searchQuery?: string;
    isTravelOnly?: boolean;
    onSubmit: (combinedJourneys: ICombinedJourneys) => void;
    countries: Array<IEventCountriesWithStops>;
    destinationBusStopId?: number;
    count: number;
    totalPrice?: number;
    turnitEventBasketTicketsCount?: number;
}

const EventRoute = ({
    onContinue,
    data,
    destinationBusStopId,
    onSubmit,
    onBack,
    onSearch,
    searchQuery,
    countries,
    count,
    totalPrice,
    turnitEventBasketTicketsCount,
    isTravelOnly,
}: IProps) => {
    const { t, i } = useI18n('event_route');
    const [selectedCity, setSelectedCity] = React.useState<number | undefined>(
        localStorage.getCityStopId()
    );

    const handleCitySelect = (cityStopId: number) => {
        setSelectedCity(cityStopId);
        localStorage.setCityStopId(`${cityStopId}`);
    };

    const price = totalPrice ?? 0;

    return (
        <>
            <NarrowTitle className="EventRoute__Title">
                <span>{t('title')}</span>
                {!isTravelOnly && (
                    <div className="EventRoute__Title__Price">
                        <span className="EventRoute__Title__Price__Total">
                            {formatPrice(price, CurrencyEnum.EUR)}
                        </span>
                    </div>
                )}
            </NarrowTitle>
            <NarrowText className="EventRoute__Text">
                <FormattedText>
                    {i(t('text'), {
                        eventName: data?.name,
                        venueName: data?.venue?.name ?? undefined,
                    })}
                </FormattedText>
            </NarrowText>
            <div className="EventRoute">
                <InputField
                    id="event-city-search"
                    autoComplete="off"
                    placeholder={t('search.placeholder')}
                    name="event-city-search"
                    value={searchQuery}
                    onChange={e => onSearch?.(e.target.value)}
                />

                {countries?.map((country, index) => (
                    <EventRouteCountry
                        key={index}
                        country={country}
                        count={count}
                        destinationBusStopId={destinationBusStopId}
                        data={data}
                        onSubmit={onSubmit}
                        onCitySelect={handleCitySelect}
                        selectedCity={selectedCity}
                    />
                ))}
            </div>
            <div className="Narrow__Buttons">
                <button
                    className="Button--inline Button Button--back Button--secondary"
                    onClick={onBack}
                >
                    <Icon name={ICON_NAME.SMALL_ARROW} />
                    {t('back-title')}
                </button>
                <button
                    disabled={!turnitEventBasketTicketsCount}
                    className="Button--inline Button"
                    onClick={onContinue}
                >
                    {t('button-title')}
                </button>
            </div>
        </>
    );
};

export default EventRoute;
