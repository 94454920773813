import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { EventBySlugQuery } from '../../../../graphql';
import { formatDate } from 'lib/helpers';
import './index.scss';
import DelayedCounter from '@slkit/Counter/DelayedCounter';

interface IProps {
    data: EventBySlugQuery['eventBySlug'];
    onContinue: () => void;
    ticketsCount: number;
    onCountChange: (count: number) => void;
}

const EventTravelOnly = ({
    onContinue,
    data,
    ticketsCount,
    onCountChange,
}: IProps) => {
    const { t } = useI18n('event_passengers_count');

    const date = formatDate(data?.date, 'DD.MM.');
    return (
        <>
            <NarrowTitle className="EventTravelOnly__Title">
                {date} - {data?.name}
            </NarrowTitle>
            <NarrowText className="EventTravelOnly__Text">
                {t('text')}
            </NarrowText>
            <div className="EventTravelOnly">
                <div className="EventTravelOnly__Counter">
                    {t('count')}
                    <DelayedCounter
                        delay={500}
                        count={ticketsCount}
                        onChange={onCountChange}
                    />
                </div>
            </div>
            <div className="Narrow__Buttons">
                <button
                    disabled={!ticketsCount}
                    className="Button--inline Button"
                    onClick={onContinue}
                >
                    {t('button-title')}
                </button>
            </div>
        </>
    );
};

export default EventTravelOnly;
