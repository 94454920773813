import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import * as React from 'react';
import {
    PriceClassEnum,
    useAddEventBasketJourneyMutation,
    useCreateEventBasketMutation,
    useCreateTurnitEventBasketMutation,
    useDeleteBasketMutation,
    useEventBySlugQuery,
} from '../../../../graphql';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import EventRoute from './EventRoute';
import { UEFAAPIContext } from '../constants';
import './index.scss';
import { ICombinedJourneys } from './EventRouteResults/EventRouteResultsJourney';
import useEventTurnitBasketContent from 'lib/hooks/useEventTurnitBasketContent';
import { Redirect } from 'react-router-dom';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useQuery from 'lib/hooks/useQuery';
import { combineUrlWithQueryParams } from '../utils';

export interface IEventStop {
    stopId: number;
    countryId: number;
    cityId: number;
    cityName: string;
}

export interface IEventCountriesWithStops {
    countryName: string;
    cities: Array<IEventStop>;
}

const EventRouteContainer = ({ slug }: { slug: string }) => {
    const queryParams = useQuery<{
        isTravelOnly: string;
        count: string;
    }>();
    const isTravelOnly = queryParams?.isTravelOnly === 'true';
    const countQuery = parseInt(queryParams?.count ?? '0');

    const basket = useEventBasketContent();
    const turnitEventBasket = useEventTurnitBasketContent();
    const { setEventBasketId, basketEventSlug } = React.useContext(
        ApplicationDataContext
    );

    const [searchedString, setSearchedString] = React.useState<string>('');
    const [error, setError] = React.useState<Error | undefined>();
    const history = useHistory();

    const { loading, beginLoading, endLoading } = useLoading();

    const { data } = useEventBySlugQuery({
        context: UEFAAPIContext,
        variables: {
            slug,
        },
    });

    const [deleteBasket] = useDeleteBasketMutation({
        context: UEFAAPIContext,
    });
    const [createTurnitEventBasket] = useCreateTurnitEventBasketMutation({
        context: UEFAAPIContext,
    });
    const [addEventJourneysMutation] = useAddEventBasketJourneyMutation({
        context: UEFAAPIContext,
    });
    const [createEventBasketMutation] = useCreateEventBasketMutation({
        variables: { eventId: data?.eventBySlug?.id! },
        context: UEFAAPIContext,
    });

    const handleError = (e: Error | undefined) => {
        endLoading();
        setError(e);
    };

    const handleSubmit = async (combinedJourneys: ICombinedJourneys) => {
        beginLoading();
        if (basket?.data?.eventBasket?.externalBasketId) {
            await deleteBasket({
                variables: {
                    id: basket.data.eventBasket.externalBasketId,
                },
            }).catch(() => endLoading());
        }

        const basketIdPromise =
            basket.data?.eventBasket?.id && slug === basketEventSlug
                ? Promise.resolve({
                      basketId: basket.data?.eventBasket?.id!,
                      basketValidUntil: basket.data?.eventBasket?.expiresAt,
                  })
                : createEventBasketMutation().then(
                      result =>
                          result.data && {
                              basketId: result.data.createEventBasket.id!,
                              basketValidUntil:
                                  result.data.createEventBasket.expiresAt,
                          }
                  );

        basketIdPromise
            .then(createdBasket => {
                createTurnitEventBasket({
                    variables: {
                        id: createdBasket?.basketId!,
                    },
                })
                    .then(() => {
                        const variables = {
                            eventBasketId: createdBasket?.basketId!,
                            journeysInput: {
                                inboundJourney: {
                                    journeyId: combinedJourneys.inboundJourney
                                        ?.id!,
                                    priceClass: combinedJourneys.inboundJourney
                                        ?.campaignPrice
                                        ? PriceClassEnum.CAMPAIGN
                                        : PriceClassEnum.REGULAR,
                                },
                                outboundJourney: {
                                    journeyId:
                                        combinedJourneys.outboundJourney.id,
                                    priceClass: combinedJourneys.outboundJourney
                                        ?.campaignPrice
                                        ? PriceClassEnum.CAMPAIGN
                                        : PriceClassEnum.REGULAR,
                                },
                            },
                        };

                        addEventJourneysMutation({ variables })
                            .then(() =>
                                setEventBasketId(
                                    createdBasket?.basketId!,
                                    createdBasket?.basketValidUntil,
                                    slug
                                )
                            )
                            .then(() => basket.reload && basket.reload())
                            .then(() => endLoading())
                            .catch(handleError);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    };

    const handleBack = React.useCallback(() => {
        window.scrollTo(0, 0);

        if (isTravelOnly) {
            history.push(
                combineUrlWithQueryParams(
                    `/event/${slug}/purchase/passengers-count`,
                    queryParams
                )
            );
        } else {
            history.push(`/event/${slug}/purchase/match`);
        }
    }, [history]);

    const handleContinue = React.useCallback(() => {
        window.scrollTo(0, 0);
        const url = combineUrlWithQueryParams(
            `/event/${slug}/purchase/passengers`,
            queryParams
        );

        history.push(url);
    }, [history, slug]);

    React.useEffect(() => {
        if (basketEventSlug && basketEventSlug !== slug) {
            setEventBasketId();
            handleBack();
        }
    }, [basketEventSlug]);

    const countriesWithStops = React.useMemo(() => {
        const rawStops = data?.eventBySlug?.eventStops?.firstStops || [];
        const stops = rawStops.reduce((acc, stop) => {
            const countryId = stop?.countryId;
            if (!countryId) return acc;

            if (!acc[countryId]) {
                acc[countryId] = {
                    countryName: stop?.countryName,
                    cities: [],
                };
            }

            acc[countryId].cities.push({
                stopId: stop?.id,
                cityId: stop?.cityId,
                cityName: stop?.cityName,
                countryId: stop?.countryId,
            });

            return acc;
        }, {} as { [key: string]: IEventCountriesWithStops });

        return Object.values(stops);
    }, [data?.eventBySlug?.eventStops]);

    const filteredCountriesWithStops = React.useMemo(() => {
        if (!searchedString) return countriesWithStops;

        const normalizeString = (str: string) =>
            str
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();

        const lowercasedSearchTerm = normalizeString(searchedString);
        return countriesWithStops
            .map(country => ({
                ...country,
                cities: country.cities.filter(city =>
                    normalizeString(city.cityName).includes(
                        lowercasedSearchTerm
                    )
                ),
            }))
            .filter(country => country.cities.length > 0);
    }, [searchedString, countriesWithStops]);

    const basketCount = basket?.data?.eventBasket?.ticketsCount ?? 0;
    const count = countQuery > basketCount ? countQuery : basketCount;

    const handleSearchChange = (searchTerm: string) => {
        setSearchedString(searchTerm);
    };

    if (!basket.loading && !basket?.data?.eventBasket && !isTravelOnly) {
        window.scrollTo(0, 0);
        return <Redirect to={`/event/${slug}/purchase/match`} />;
    }

    return (
        <>
            {(loading || turnitEventBasket.loading || basket.loading) && (
                <LoadingIndicator />
            )}

            {data && (
                <EventRoute
                    totalPrice={
                        turnitEventBasket?.data?.basket?.content?.totalPrice ??
                        (basket?.data?.eventBasket?.totalPriceCents ?? 0) / 100
                    }
                    data={data.eventBySlug}
                    count={count}
                    isTravelOnly={isTravelOnly}
                    onSubmit={handleSubmit}
                    onContinue={handleContinue}
                    onSearch={handleSearchChange}
                    searchQuery={searchedString}
                    countries={filteredCountriesWithStops}
                    onBack={handleBack}
                    destinationBusStopId={
                        data.eventBySlug?.eventStops.lastStop?.id
                    }
                    turnitEventBasketTicketsCount={
                        turnitEventBasket.data?.basket?.content.passengers
                            ?.length
                    }
                />
            )}

            <ErrorMessage
                error={error}
                fixed
                onClose={() => setError(undefined)}
            />
        </>
    );
};

export default EventRouteContainer;
