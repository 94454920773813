import Header from '@slkit/Header';
import BasketEventCheckerContainer from 'components/BasketChecker/BasketEventCheckerContainer';
import LanguagePicker from 'components/LanguagePicker';
import useMeta from 'lib/hooks/useMeta';
import * as React from 'react';

const EventNavbar = () => {
    const version = useMeta('x-show-version');
    return (
        <Header version={version}>
            <LanguagePicker compact direction="down" />
            <BasketEventCheckerContainer />
        </Header>
    );
};

export default EventNavbar;
