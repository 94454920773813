import Footer from '@slkit/Footer';
import LanguagePicker from 'components/LanguagePicker';
import SupportCenterLink from 'components/SupportCenterLink';
import * as React from 'react';

const index = ({ footerContent }: { footerContent?: React.ReactNode }) => (
    <Footer footerContent={footerContent}>
        <LanguagePicker direction="up" />
        <SupportCenterLink />
    </Footer>
);

export default index;
