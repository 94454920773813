import Footer from 'components/Footer';
import EventNavbar from 'components/Navbar/EventNavbar';
import * as React from 'react';

interface IProps {
    footerContent?: React.ReactNode;
    children: React.ReactNode;
}

const EventLayout = ({ children, footerContent }: IProps) => (
    <>
        <EventNavbar />
        {children}
        <div id="fixed-notifications-container" />
        <Footer footerContent={footerContent} />
    </>
);

export default EventLayout;
