import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';
import PassengerForm from './PassengerForm';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import useI18n from 'lib/hooks/useI18n';

interface IProps {
    entry: IBasketEntry;
    passengers: IPassengerData[];
    onChange: (id: string, changes: IPassengerDataAttributes) => void;
}

const EntryPassengersForm = ({ entry, passengers, onChange }: IProps) => {
    const { t } = useI18n('event_passengers_info');
    const entryPassengers = passengers.filter(
        p => entry.products.find(product => product.productId === p.id) != null
    );
    return (
        <>
            {entryPassengers.map(
                (passenger, index) =>
                    passenger && (
                        <React.Fragment key={passenger.id}>
                            {index === 1 && (
                                <NarrowSubtitle>
                                    {t('other_passengers')}
                                </NarrowSubtitle>
                            )}

                            <PassengerForm
                                onChange={onChange}
                                passenger={passenger}
                            />
                        </React.Fragment>
                    )
            )}
        </>
    );
};

export default EntryPassengersForm;
