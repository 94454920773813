import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { useHistory } from 'lib/hooks/useRouter';
import * as React from 'react';
import {
    useDeleteEventTurnitBasketMutation,
    useEventBySlugQuery,
} from '../../../../graphql';
import useEventBasketContent from 'lib/hooks/useEventBasketContent';
import { UEFAAPIContext } from '../constants';
import NoResultEvent from '@slkit/NoResult/NoResultEvent';
import FormattedText from 'components/FormattedText';
import useI18n from 'lib/hooks/useI18n';
import useEventTurnitBasketContent from 'lib/hooks/useEventTurnitBasketContent';
import EventTravelOnly from './EventTravelOnly';
import { combineUrlWithQueryParams } from '../utils';
import useQuery from 'lib/hooks/useQuery';
import useLoading from 'lib/hooks/useLoading';
import { ApplicationDataContext } from 'lib/applicationDataContext';

export interface IEventCount {
    [key: string]: number;
}

const EventTravelOnlyContainer = ({ slug }: { slug: string }) => {
    const { t: tError } = useI18n('event_error');
    const basket = useEventBasketContent();
    const queryParams = useQuery();
    const turnitEventBasket = useEventTurnitBasketContent();
    const { setEventTurnitBasketId, setEventBasketId } = React.useContext(
        ApplicationDataContext
    );
    const { loading: customLoading, beginLoading, endLoading } = useLoading();

    const parsedCount = parseInt(queryParams?.count ?? '0');

    const [count, setCount] = React.useState<number>(
        turnitEventBasket?.data?.basket?.content?.passengers?.length ??
            parsedCount ??
            0
    );

    const history = useHistory();
    const [error, setError] = React.useState<Error | undefined>();

    const [deleteBasket] = useDeleteEventTurnitBasketMutation({
        context: UEFAAPIContext,
    });

    const { data, loading } = useEventBySlugQuery({
        context: UEFAAPIContext,
        variables: {
            slug,
        },
    });

    const handleNewCount = (newCount: number) => {
        setCount(newCount);

        if (basket?.data?.eventBasket?.externalBasketId) {
            beginLoading();
            deleteBasket({ variables: { id: basket?.data?.eventBasket?.id } })
                .then(() => basket.reload && basket.reload())
                .then(() => setEventTurnitBasketId())
                .then(() => endLoading())
                .catch(() => endLoading());
        }
    };

    const handleContinue = () => {
        const queryParams = {
            count,
            isTravelOnly: true,
        };
        const url = combineUrlWithQueryParams(
            `/event/${slug}/purchase/route`,
            queryParams
        );

        window.scrollTo(0, 0);
        history.push(url);
    };

    React.useEffect(() => {
        if (basket.data?.eventBasket?.items.length) {
            setEventBasketId();
        }
    }, [basket.data?.eventBasket]);

    const availableTickets = data?.eventBySlug?.pools?.reduce(
        (acc, val) => acc + val.listedTickets,
        0
    );

    const isLoading = loading || basket.loading || customLoading;

    if (
        !isLoading &&
        data &&
        !data?.eventBySlug?.isPurchasable &&
        !data.eventBySlug?.purchasableFinished
    ) {
        return (
            <NoResultEvent title={tError('not_purchasable.title')}>
                <FormattedText>{tError('not_purchasable.text')}</FormattedText>
            </NoResultEvent>
        );
    }

    if (
        !isLoading &&
        data &&
        (!availableTickets || data?.eventBySlug?.purchasableFinished)
    ) {
        return (
            <NoResultEvent title={tError('no_tickets.title')}>
                <FormattedText>{tError('no_tickets.text')}</FormattedText>
            </NoResultEvent>
        );
    }

    return (
        <>
            {isLoading && <LoadingIndicator />}

            {data && (
                <EventTravelOnly
                    key={basket.data?.eventBasket?.id}
                    data={data.eventBySlug}
                    ticketsCount={count}
                    onContinue={handleContinue}
                    onCountChange={handleNewCount}
                />
            )}

            <ErrorMessage
                error={error}
                fixed
                onClose={() => setError(undefined)}
            />
        </>
    );
};

export default EventTravelOnlyContainer;
