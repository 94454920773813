import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { parseNames } from 'lib/helpers';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBuyerInfo from 'lib/hooks/useBuyerInfo';
import useI18n from 'lib/hooks/useI18n';
import orderBy from 'lodash/orderBy';
import { IUpdateBuyerInfo, IUpdateCustomerInfo } from 'mutations';
import { BuyerInfo_basket_buyerInfo } from 'queries/__types__/BuyerInfo';
import * as React from 'react';

import { BasketContentQuery } from '../../../../graphql';
import usePassengersFormReducer, {
    Action,
} from 'components/PurchaseProcess/Passengers/usePassengersFormReducer';

export const ADULT_FARE_CLASS = 'BONUS_SCHEME_GROUP.ADULT';

interface IValidationOptions {
    t: (key: string) => string;
    validatePassengerName?: boolean;
    validateBuyerName?: boolean;
    validateBuyerEmail?: boolean;
    validateBuyerPhone?: boolean;
}

interface IValidationResult {
    [passengerId: string]: {
        [field: string]: string;
    };
}

const validateData = (
    passengers: IPassengerData[],
    options: IValidationOptions
): IValidationResult | null => {
    const {
        t,
        validateBuyerEmail,
        validateBuyerName,
        validateBuyerPhone,
        validatePassengerName,
    } = options;
    const errors: IValidationResult = {};

    passengers.forEach(p => {
        if (validatePassengerName === undefined || validatePassengerName) {
            const normalizedName = (p.name || '').trim();
            if (normalizedName.length === 0) {
                errors[p.id] = errors[p.id] || {};
                errors[p.id].name = t('name-required');
            }
        }

        if (p.isBuyer) {
            if (validateBuyerEmail === undefined || validateBuyerEmail) {
                const normalizedEmail = (p.email || '').trim();
                if (normalizedEmail.length === 0) {
                    errors[p.id] = errors[p.id] || {};
                    errors[p.id].email = t('email-required');
                } else if (!normalizedEmail.match(/.+\@.+\..+/)) {
                    errors[p.id] = errors[p.id] || {};
                    errors[p.id].email = t('email-invalid');
                }
            }
            if (validateBuyerName === undefined || validateBuyerName) {
                const normalizedName = (p.name || '').trim();
                if (normalizedName.length === 0) {
                    errors[p.id] = errors[p.id] || {};
                    errors[p.id].name = t('name-required');
                }
            }
            if (validateBuyerPhone === undefined || validateBuyerPhone) {
                const normalizedNumber = (p.phoneNumber || '').trim();
                if (normalizedNumber.length === 0) {
                    errors[p.id] = errors[p.id] || {};
                    errors[p.id].phoneNumber = t('number-required');
                }
            }
        }
    });

    if (Object.keys(errors).length === 0) return null;

    return errors;
};

const buildBuyerErrors = (
    error: ApolloError
): IPassengerDataErrors | undefined => {
    if (!error.graphQLErrors) return undefined;

    const errs = (error.graphQLErrors[0] as unknown) as IGraphQLError;
    const messages = errs && errs.errors && errs.errors.messages;

    if (!messages) return undefined;

    const errors: IPassengerDataErrors = {};

    if (messages.email) {
        errors.email = messages.email.join(', ');
    }

    if (messages.name) {
        errors.name = messages.name.join(', ');
    }

    if (messages.phone_number) {
        errors.phoneNumber = messages.phone_number.join(', ');
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    } else {
        return undefined;
    }
};

const buildPassenger = (
    item: NonNullable<BasketContentQuery['basket']['content']['passengers']>[0]
): IPassengerData => ({
    email: null,
    id: item.productId || '',
    isBuyer: false,
    name: (item.passengerName && item.passengerName.trim()) || null,
    phoneAreaCode: '+421',
    phoneNumber: null,
    requestedFareClass: item.fareClassId,
});

// This function mutates passenger on purpose
const setBuyer = (p: IPassengerData, bi: BuyerInfo_basket_buyerInfo): void => {
    if (!p || !bi) return;
    p.isBuyer = true;
    p.name = p.name || `${bi.firstName || ''} ${bi.lastName || ''}`.trim();
    p.email = p.email || bi.email;
    p.phoneAreaCode = bi.phoneAreaCode || p.phoneAreaCode;
    p.phoneNumber = p.phoneNumber || bi.phoneNumber;
};

const initializePassengers = (
    passengers: NonNullable<
        BasketContentQuery['basket']['content']['passengers']
    >,
    buyerInfo: BuyerInfo_basket_buyerInfo
) => {
    const passengersData = passengers.map(buildPassenger);

    const buyerName = `${buyerInfo.firstName} ${buyerInfo.lastName}`.trim();
    const buyer = passengersData.find(p => p.name === buyerName);

    if (!buyer) {
        // buyer was not found by name
        // find first adult
        const firstAdult = passengersData.find(
            p => p.requestedFareClass === ADULT_FARE_CLASS
        );
        if (firstAdult) {
            // Set first adult as buyer
            setBuyer(firstAdult, buyerInfo);
        } else {
            // There is no adult, make first passenger the buyer
            setBuyer(passengersData[0], buyerInfo);
        }
    } else {
        // buyer was found by name
        // There is no adult, make first passenger the buyer
        setBuyer(buyer, buyerInfo);
    }

    return orderBy(passengersData, ['isBuyer', 'requestedFareClass'], ['desc']);
};

const submitForm = (
    basketId: string,
    passengers: IPassengerData[],
    actions: {
        onError: (id: string, errors?: IPassengerDataErrors) => void;
        updateBuyerInfo?: IUpdateBuyerInfo;
        updateCustomerInfo?: IUpdateCustomerInfo;
    }
): Promise<boolean> => {
    const passengersPromiseGroup: Array<Promise<boolean>> = [];

    for (const passenger of passengers) {
        const [firstName, lastName] = parseNames(passenger.name || '');
        passengersPromiseGroup.push(
            actions.updateCustomerInfo!(basketId, passenger.id, {
                passengerFirstName: firstName,
                passengerLastName: lastName || '',
            }).then(() => true)
            // Might wanna handle errors when customer cant be updated
        );
    }

    const buyer = passengers.find(p => !!p.isBuyer);

    if (buyer) {
        const [firstName, lastName] = parseNames(buyer.name || '');
        passengersPromiseGroup.push(
            actions.updateBuyerInfo!(basketId, {
                country: buyer.country,
                email: buyer.email,
                firstName,
                lastName: lastName || '',
                phoneAreaCode: buyer.phoneAreaCode,
                phoneNumber: buyer.phoneNumber,
            })
                .then(() => true)
                .catch((error: any) => {
                    const errors = buildBuyerErrors(error as ApolloError);
                    if (errors) {
                        actions.onError(buyer.id, errors);
                        return false;
                    } else {
                        throw error;
                    }
                })
        );
    }

    return Promise.all(passengersPromiseGroup).then(res =>
        res.reduce((p, c) => p && c, true)
    );
};

const usePassengersForm = (basketId?: string) => {
    const { t } = useI18n('passengers_info.validation');
    const { updateBuyerInfo, updateCustomerInfo } = React.useContext(
        ApplicationDataContext
    );
    const [passengers, dispatch] = usePassengersFormReducer();

    const {
        data: basketData,
        loading: basketLoading,
        error: basketError,
        reload: basketReload,
    } = useBasketContent(basketId);
    const {
        data: buyerData,
        loading: buyerLoading,
        error: buyerError,
        reload: buyerReload,
    } = useBuyerInfo(basketId);

    const handleReload = () => {
        const promises: Array<Promise<any>> = [];
        if (basketReload) promises.push(basketReload());
        if (buyerReload) promises.push(buyerReload());
        return Promise.all(promises);
    };

    React.useEffect(() => {
        if (basketData && buyerData) {
            const pData = basketData.basket.content.passengers || [];
            const bData = buyerData.basket.buyerInfo;
            const payload = initializePassengers(pData, bData);

            dispatch({
                payload,
                type: Action.INITIALIZE,
            });
        }
    }, [buyerData, basketData]);

    const changeHandler = React.useCallback(
        (id: string, attributes: IPassengerDataAttributes) => {
            const payload = { id, ...attributes };
            dispatch({
                payload,
                type: Action.UPDATE_PASSENGER,
            });
        },
        [dispatch]
    );

    const errorHandler = React.useCallback(
        (id: string, errors?: IPassengerDataErrors) => {
            const payload = { id, errors };
            dispatch({
                payload,
                type: Action.UPDATE_PASSENGER_ERRORS,
            });
        },
        [dispatch]
    );

    return {
        error: basketError || buyerError,
        handlePassengerChange: changeHandler,
        loading: basketLoading || buyerLoading,
        passengers,
        reload: handleReload,
        submit: (basketId: string) => {
            const res = validateData(passengers, {
                t,
            });
            if (res) {
                for (const pId in res) {
                    if (res.hasOwnProperty(pId)) {
                        const pErrors = res[pId];
                        errorHandler(pId, pErrors);
                    }
                }
                return Promise.reject(undefined);
            } else {
                return submitForm(basketId, passengers, {
                    onError: errorHandler,
                    updateBuyerInfo,
                    updateCustomerInfo,
                });
            }
        },
    };
};

export default usePassengersForm;
