import { ApolloError, NetworkStatus } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import * as React from 'react';

import { BasketContentQuery, useBasketContentQuery } from '../../../graphql';

interface IEventTurnitBasketContentResult {
    loading: boolean;
    data?: BasketContentQuery;
    error?: ApolloError;
    reload: () => Promise<any>;
    validUntil?: string;
    isTravelPassesOnly?: boolean;
    journeyBrandName?: string;
    journeyPairSpecialName?: string;
}

const isTravelPassesOnly = (basket?: BasketContentQuery['basket']): boolean => {
    if (!basket) return false;
    return false;
};

const dummyReload = () => {
    console.warn('[useEventTurnitBasketContent] dummyReloadCalled');
    return Promise.resolve(false);
};

const useEventTurnitBasketContent = (): IEventTurnitBasketContentResult => {
    const { eventTurnitBasketId } = React.useContext(ApplicationDataContext);

    const turnitBasketQuery = useBasketContentQuery({
        skip: !eventTurnitBasketId,
        variables: { id: eventTurnitBasketId! },
        notifyOnNetworkStatusChange: true,
    });

    if (!eventTurnitBasketId) {
        return {
            loading: false,
            reload: dummyReload,
        } as IEventTurnitBasketContentResult;
    }

    const loading = turnitBasketQuery.loading;
    const error = turnitBasketQuery.error;

    const data = turnitBasketQuery.data;

    const getJourneyPair = (
        journeyType: 'inboundJourney' | 'outboundJourney'
    ) => {
        const passengers = data?.basket?.content?.passengers;

        const journey = passengers?.[0]?.[journeyType]?.legs?.[0];
        return `${journey?.departureStopId ?? ''}-${
            journey?.destinationStopId ?? ''
        }`;
    };

    const journeyBrandName =
        turnitBasketQuery?.data?.basket?.content?.passengers?.[0]
            ?.inboundJourney?.legs?.[0]?.brandName ?? '';

    const outbound = getJourneyPair('outboundJourney');
    const inbound = getJourneyPair('inboundJourney');

    const journeyPairSpecialName = `${outbound}_${inbound}_${journeyBrandName}`;

    return {
        journeyBrandName,
        journeyPairSpecialName,
        error,
        loading,
        data: turnitBasketQuery.data,
        isTravelPassesOnly: data && isTravelPassesOnly(data.basket),
        reload: turnitBasketQuery.refetch,
    };
};

export default useEventTurnitBasketContent;
